import React from "react";

function WorkExperience() {
  return (
    <section id="work-experience" className="work-experience-section">
      <h2>Work Experience</h2>

      <div className="experience-item">
        <h3>Software Developer, VARLab Co-op</h3>
        <p><strong>Waterloo, Ontario</strong> | <em>May 2022 – January 2024</em></p>
        <ul>
          <li>Collaborated and led a team in an agile software development environment.</li>
          <li>Developed 3D and 2D WebGL software using Unity and C# scripting.</li>
          <li>Documented development and testing using Jira and Confluence.</li>
          <li>Communicated with team members, coordinators, and stakeholders on development, ideas, and demoing work.</li>
          <li>Successfully deployed projects like a sanitation learning simulator for OMAFRA, bulldozer and excavator inspections, and an ambulance orientation simulator.</li>
        </ul>
      </div>

      <div className="other-experience">
        <h3>Other Experience</h3>
        <div className="other-experience-item">
          <p><strong>Grocery Clerk</strong> at Tim and Heather's No Frills, Shelburne, Ontario (Feb 2019 – Aug 2021)</p>
          <ul>
            <li>Communicated with customers to solve issues and improve their experience.</li>
          </ul>
        </div>
        <div className="other-experience-item">
          <p><strong>Data Clerk</strong> at CCI Resources, Orangeville, Ontario (July 2019 – Aug 2019)</p>
          <ul>
            <li>Scanned and retrieved data from paper surveys, ensuring accuracy in data entry.</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default WorkExperience;
