import React from "react";

function ContactMe() {
  return (
    <section id="contact-me" className="contact-me-section">
      <h2>Contact Me</h2>
      <p>
        Feel free to reach out to me with any questions or opportunities. You can email me directly at{" "}
        <a href="mailto:Jonah@JonahPearce.ca" className="email-link">
          Jonah@JonahPearce.ca
        </a>.
      </p>
      <p>
        Alternatively, you can connect with me on{" "}
        <a href="https://www.linkedin.com/in/jonahlpearce/" target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>{" "}
        or check out my projects on{" "}
        <a href="https://github.com/JonahPearce" target="_blank" rel="noopener noreferrer">
          GitHub
        </a>.
      </p>
    </section>
  );
}

export default ContactMe;
