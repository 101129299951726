// Profile.jsx
import React from "react";

function Profile() {
  return (
    <section id="profile" className="profile-section">
      <div className="profile-container">
        <img
          src="https://via.placeholder.com/150"
          alt="Profile"
          className="profile-picture"
        />
        <h1>Jonah Pearce</h1>
        <p>
          Seeking a software development position to leverage my abilities in
          full-stack development, security architecture, and agile methodologies
          to continue to develop robust and scalable software solutions.
        </p>
      </div>
    </section>
  );
}

export default Profile;
