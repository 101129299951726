import React from "react";

function ProfessionalProjects() {
  return (
    <section id="professional-projects" className="professional-projects-section">
      <h2>Professional Projects</h2>

      <div className="project-item">
        <h3>OMAFRA Meat Safety</h3>
        <p>
          Worked on a complex Unity-based project involving creating simulations for safety training. Developed scenarios effectively using Unity and C# scripting.
        </p>
      </div>

      <div className="project-item">
        <h3>Custom Unity UI Library</h3>
        <p>
          Developed an entire custom UI library for universal use across all projects at the VARLab. Gained deep knowledge of UI design, user experience, and reusable UI components.
        </p>
      </div>

      <div className="project-item">
        <h3>Heavy Equipment Simulations</h3>
        <p>
          Worked on the bulldozer and excavator projects, creating WebGL simulations in Unity. Focused on bug fixing, quality assurance, and thorough testing.
        </p>
      </div>
    </section>
  );
}

export default ProfessionalProjects;
