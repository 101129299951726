import React from "react";

function Skills() {
  const skills = [
    "JavaScript", "React", "C", "C#", "C++", "Java", "Unity", 
    "Express", "WebGL", "HTML", "ASP.NET MVC", 
    "MySQL", "MongoDB", "GitHub", "Sourcetree", 
    "Jira", "Confluence", "Azure DevOps", 
    "Penetration Testing", "Malware Analysis", "Cryptography", 
    "Linux", "Windows", "Android"
  ];

  return (
    <section id="skills" className="skills-section">
      <h2>Skills</h2>
      <p>
        Here are the technologies I work with. I'm always looking to learn new things and expand my skill set.
      </p>
      <div className="skills-list">
        {skills.map((skill, index) => (
          <span key={index} className="skill-item">{skill}</span>
        ))}
      </div>
    </section>
  );
}

export default Skills;
