// Navbar.jsx
import React from "react";

function Navbar() {
  return (
    <nav className="navbar">
      <ul className="navbar-links">
        <li><a href="#profile">Profile</a></li>
        <li><a href="#work-experience">Work Experience</a></li>
        <li><a href="#professional-projects">Professional Projects</a></li>
        <li><a href="#other-projects">Other Projects</a></li>
        <li><a href="#skills">Skills</a></li>
        <li><a href="#contact-me">Contact Me</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;