import React from "react";

function OtherProjects() {
  return (
    <section id="other-projects" className="other-projects-section">
      <h2>Other Projects</h2>

      <div className="project-item">
        <h3>Tap and Learn</h3>
        <p>
          <strong>Description:</strong> A student capstone project integrating NFC technology with online student courses. Verified student attendance and restricted training and examination content to NFC tags.
        </p>
        <p>
          <strong>Technology:</strong> Built using the MERN stack, integrating React frontend, Express backend, and LMS via learning tools interoperability.
        </p>
        <p>
          <strong>Results:</strong> Successfully developed a test environment that attracted industry interest, with ongoing work for full deployment.
        </p>
      </div>

      <div className="project-item">
        <h3>Stealth Chat</h3>
        <p>
          <strong>Description:</strong> A complex web app designed with security and privacy controls from inception, providing hands-on experience in secure development.
        </p>
        <p>
          <strong>Technology:</strong> Built using ASP.NET Core MVC, employing privacy-enhancing technologies like pseudonymization and TLS.
        </p>
        <p>
          <strong>Results:</strong> Delivered a fully functional application with penetration testing, security controls, and comprehensive privacy policies.
        </p>
      </div>

      <div className="project-item">
        <h3>Avionic Monitoring Software</h3>
        <p>
          <strong>Description:</strong> Simulated communication between in-flight airplanes and ground control using client-server architecture.
        </p>
        <p>
          <strong>Technology:</strong> Developed in C++ with network programming (Winsock) and a MySQL database for data storage.
        </p>
        <p>
          <strong>Results:</strong> Delivered a high-graded, compliant application capable of handling unlimited clients, meeting avionic software standards.
        </p>
      </div>
    </section>
  );
}

export default OtherProjects;
