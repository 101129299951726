// App.jsx
import React from "react";
import Navbar from "./components/Navbar";
import Profile from "./components/Profile";
import WorkExperience from "./components/WorkExperience";
import ProfessionalProjects from "./components/ProfessionalProjects";
import OtherProjects from "./components/OtherProjects";
import Skills from "./components/Skills";
import ContactMe from "./components/ContactMe";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Profile />
      <WorkExperience />
      <ProfessionalProjects />
      <OtherProjects />
      <Skills />
      <ContactMe />
    </div>
  );
}

export default App;